<template>
  <div>
    <BModal
      id="internalNotes"
      v-model="showInternalNotes"
      title="Paging Notes"
    >
      <Msntable
        v-if="internalNotesTable.length > 1"
        :rows="internalNotesTable"
      />
      <span v-else-if="notesLoaded">
        No notes for this journal
      </span>
      <span v-else>
        Loading Notes...
      </span>
      <template slot="modal-footer">
        <BButton
          @click="showInternalNotes = false"
          name="OK button"
          variant="primary"
        >
          OK
        </BButton>
      </template>
    </BModal>
    <div v-if="!loadingPage && (groupId === undefined || groupId <= 0) && seriesId <= -1">
      <BRow>
        <BCol>
          <h1 class="no-bottom text-ams-orange">
            No serials found for that ID.
          </h1>
        </BCol>
      </BRow>
      <BRow>
        <BButton
          class="btn-ams-blue-dark indent"
          onclick="history.back()"
        >
          Back
        </BButton>
      </BRow>
    </div>
    <div v-else-if="!loadingPage">
      <BRow>
        <BCol>
          <span class="font-italic">
            Profile for
          </span>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <!-- <h1 class="no-bottom text-ams-orange">
            {{ fullName }}
          </h1> -->
          <PageHeader>
            {{ fullName }}
          </PageHeader>
          <!-- <span
            v-if="internal && jourCode !== ''"
            class="abbr indent"
          >
            {{ jourCode }}
          </span> -->
        </BCol>
      </BRow>
      <BRow v-if="internal">
        <BCol>
          <span
            v-if="internal && jourCode !== ''"
            class="abbr indent"
          >
            {{ jourCode }}
          </span>
        </BCol>

        <BCol>
          <div class="float-right">
            <div v-if="mcqValue > -1">
              <span :title="'MCQ for this ' + serialType">
                <span class="font-weight-bold">
                  MCQ:
                </span>
                {{ mcqValue }}
              </span>
              <span
                class="ml-3"
                :title="'Median MCQ for all ' + serialType + 's'"
              >
                <span class="font-weight-bold">
                  Median MCQ:
                </span>
                {{ mcqAllValue }}
              </span>
            </div>
            <div v-else>
              <span :title="'MCQ for this ' + serialType">
                <span class="font-weight-bold">
                  MCQ:
                </span> loading...
              </span>
              <span
                class="ml-3"
                :title="'Median MCQ for all ' + serialType + 's'"
              >
                <span class="font-weight-bold">
                  Median MCQ:
                </span> loading...
              </span>
            </div>
            <BButton
              v-if="internal && isJournal"
              variant="ams-blue-dark"
              class="float-right"
              :disabled="internalNotesTable.length == 1"
              @click="showInternalNotes = !showInternalNotes"
            >
              <span v-if="internalNotesTable.length > 1">
                Show
              </span><span v-else-if="!notesLoaded">
                Loading
              </span><span v-else>
                No
              </span> Paging Notes
            </BButton>
          </div>
        </BCol>
      </BRow>
      <BRow>
        <BCol
          col
          xs="12"
        >
          <SerialDetails
            :old-jour-id="oldJourId"
            :group-id="groupId"
            :series-id="seriesId"
            @return="detailsReturn"
          />
          <SerialVolIssCard
            v-if="historyTable.length > 2"
            :recent-type="recentType"
            :recent-link="recentLink"
            :vol-iss-formatted="volIssFormatted"
            :unassigned="unassigned"
            :internal="internal"
          />
        </BCol>
        <BCol
          col
          xs="12"
        >
          <SerialGroupDetails
            v-if="true && history.length > 1"
            :serial-type="serialType"
            :group-details-table="groupDetailsTable"
          />
          <SerialVolIssCard
            v-if="historyTable.length <= 2"
            :recent-type="recentType"
            :recent-link="recentLink"
            :vol-iss-formatted="volIssFormatted"
            :unassigned="unassigned"
            :internal="internal"
          />
          <Card
            :title="toTitleCase(serialType) + ' Title History'"
            :rows="historyTable"
            :nav-btn="historyLink"
          />
          <Card
            v-if="relatedTable.length > 0"
            title="Related"
            :rows="relatedTable"
            :small-table="true"
          />
          <!--
          <Card
            v-if="internal"
            title="Mathematical Reviews"
            :rows="internalTable"
            :small-table="true"
          >
            <BButton
              v-if="internal && isJournal"
              variant="ams-blue-dark"
              :disabled="internalNotesTable.length === 1"
              @click="showInternalNotes = !showInternalNotes"
            >
              <span v-if="internalNotesTable.length > 1">
                Show
              </span><span v-else-if="!notesLoaded">
                Loading
              </span><span v-else>
                No
              </span> Paging Notes
            </BButton>
          </Card>
          -->
        </BCol>
      </BRow>
      <div class="clearline" />

      <!-- MCQ -->
      <!-- <BRow v-if="isJournal"> -->
      <BRow>
        <BCol>
          <SerialMCQ
            :group-id="groupId"
            :series-id="seriesId"
            :abbr="abbr"
            :is-ref-list="isRefList"
            @return="mcqReturn"
          />
        </BCol>
      </BRow>

      <!-- Citations -->
      <BRow>
        <BCol>
          <SerialCitations
            :group-id="groupId"
            :series-id="seriesId"
            :abbr="abbr"
            :is-ref-list="isRefList"
            :internal="internal"
          />
        </BCol>
      </BRow>

      <!-- Publications per Year -->
      <BRow>
        <BCol>
          <SerialPublications
            :group-id="groupId"
            :series-id="seriesId"
          />
        </BCol>
      </BRow>

      <BRow>
        <!-- MSC -->
        <BCol>
          <SerialMSC
            :group-id="groupId"
            :series-id="seriesId"
          />
        </BCol>

        <!-- Top Authors -->
        <BCol>
          <SerialAuthors
            :group-id="groupId"
            :series-id="seriesId"
          />
        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
import { toTitleCase, genRelatedTable, parseParams, volIssTable, genUnassigned } from '@/utils'
// import URLGen from '@/utils/url-gen'
import { JournalAPI } from '@/js-api'
import { PageHeader } from '@/components/page-header'
// import Card from '../components/journal/Card.vue'
import { updateMathJax } from '@/utils/utils'
import { trackJournalCounter5, trackSerieCounter5 } from '@/counter5-tracker'

export default {
  name: 'SerialProfile',
  title: 'Profile',
  components: {
    PageHeader,
    Card: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/JournalCard.vue'),
    Msntable: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/MsnTable.vue'),
    SerialDetails: () => import(/* webpackChunkName: "journal_serialdetails" */ '@/components/journal/SerialDetails.vue'),
    SerialGroupDetails: () => import(/* webpackChunkName: "journal_serialgroupdetails" */ '@/components/journal/SerialGroupDetails.vue'),
    SerialMCQ: () => import(/* webpackChunkName: "journal_serialmcq" */ '@/components/journal/SerialMCQ.vue'),
    SerialCitations: () => import(/* webpackChunkName: "journal_serialcitations" */ '@/components/journal/SerialCitations.vue'),
    SerialPublications: () => import(/* webpackChunkName: "journal_serialpublications" */ '@/components/journal/SerialPublications.vue'),
    SerialMSC: () => import(/* webpackChunkName: "journal_serialmsc" */ '@/components/journal/SerialMSC.vue'),
    SerialAuthors: () => import(/* webpackChunkName: "journal_serialauthors" */ '@/components/journal/SerialAuthors.vue'),
    SerialVolIssCard: () => import(/* webpackChunkName: "journal_serialvolisscard" */ '@/components/journal/SerialVolIssCard.vue'),
    // SerialVolIss: () => import(/* webpackChunkName: "journal_serialvoliss" */ '@/components/journal/SerialVolIss.vue'),
  },
  data() {
    return {
      fullName: 'Loading...',
      loadingPage: true,
      groupId: -1,
      jourId: -1,
      oldJourId: -1,
      seriesId: -1,
      // Details
      abbr: '',
      isRefList: false,
      // Internal
      jourCode: '',
      mcqValue: -1,
      mcqAllValue: -1,
      // Recent
      recent: [
        // {title, volume, issue, year, id}
      ],
      // History
      current: false,
      history: [

      ],
      internal: false,
      groupDetailsTable: [],
      // Related
      related: [
      ],
      // MCQ
      mcq: [],
      mcqAll: [],
      // mcqDescription?

      directRelTypes: [
        'Continued as',
        'Formerly',
      ],
      jourGroupLookup: {},
      duplicateGroups: [],
      renderMCQ: false,
      renderCitations: false,
      renderPublications: false,

      internalNotes: {},
      jourCodeLookup: {},
      joursFound: [], // Which ones have been looked up in getGroupIds
      unassigned: null,

      showInternalNotes: false,
      notesLoaded: false,
      alreadyTracked: false,
    }
  },
  computed: {
    internalNotesTable: function() {
      // let table = [['JourCode', 'Date', 'Initials', 'Note']]
      const table = [['Date', 'Initials', 'Note']]
      for (const i in this.internalNotes) {
        table.push(
          [
            // this.jourCodeLookup[this.internalNotes[i].jourId],
            this.internalNotes[i].date,
            this.internalNotes[i].initials,
            // this.internalNotes[i].txt,
            this.internalNotes[i].txt.replace(/\s{4,}/, '<br/>'),
          ]
        )
      }
      return table
    },
    isJournal: function() {
      return ((this.jourId && this.jourId > -1) || (this.groupId && this.groupId > -1))
    },
    serialType: function() {
      return this.isJournal ? 'journal' : 'series' // Kept lowercase for descriptions
    },
    recentType: function() {
      return this.isJournal ? 'Issues' : 'Volumes'
    },
    // internal: function () {
    //   return this.jourCode !== undefined && this.jourCode.length > 0
    // },
    internalTable: function() {
      const rows = []
      if (this.jourCode && this.jourCode.length > 0) {
        rows.push(['Journal Code', this.jourCode])
      }
      if (this.mcqValue > -1) {
        rows.push([toTitleCase(this.serialType) + ' MCQ', this.mcqValue])
      }
      if (this.mcqAllValue > -1) {
        rows.push(['All ' + toTitleCase(this.serialType) + ' MCQ', this.mcqAllValue])
      }
      return rows
    },
    volIssFormatted: function() {
      const items = this.recent.items
      const mostRecentJournalId = this.recent.mostRecentJournalId
      return volIssTable(items, !this.isJournal, undefined, mostRecentJournalId)
    },
    recentLink: function() {
      // const linkInfo = {}
      // if (this.isJournal) {
      //   if (this.groupId > -1) {
      //     linkInfo.groupId = this.groupId
      //   } else {
      //     linkInfo.journalId = this.journalId
      //   }
      // } else {
      //   linkInfo.seriesId = this.seriesId
      // }
      // return { url: URLGen.voliss(linkInfo), text: this.isJournal ? 'List All Issues' : 'List All Volumes' }
      const queryStringParams = {}
      if (this.isJournal) {
        if (this.groupId > -1) {
          queryStringParams.groupId = this.groupId
        } else {
          queryStringParams.journalId = this.journalId
        }
      } else {
        queryStringParams.seriesId = this.seriesId
      }
      return {
        text: this.isJournal ? 'List All Issues' : 'List All Volumes',
        to: {
          name: this.isJournal ? 'IssueList' : 'VolumeList',
          query: queryStringParams,
        },
      }
    },
    relatedTable: function() {
      const table = genRelatedTable(this.related, false, true, this.$router)
      return table
    },
    historyLink: function() {
      const response = {
        to: {
          name: 'History',
          query: {
            groupId: this.groupId,
          },
        },
        text: 'View Details',
      }
      if (!this.isJournal) {
        response.to.query = {
          seriesId: this.seriesId,
        }
      }
      return response
    },
    historyTable: function() {
      const rows = [
        ['Title', 'Start', 'End'],
      ]
      // Sort by start?
      for (const i in this.history) {
        rows.push(this.history[i])
      }
      return rows
    },
  },
  mounted: function() {
    this.onMount()
    updateMathJax()
  },
  updated: function() {
    updateMathJax()
  },
  methods: {
    toTitleCase(text) {
      // Used to make the Template work
      return toTitleCase(text)
    },
    setTitle: function(text) {
      text = text.replace(/\$/g, '')
      document.title = text + ' - Profile'
    },
    addTabListeners: function() {
      // Link up the MathJax listeners
      const navLinks = document.getElementsByClassName('nav-tabs')
      for (let i = 0; i < navLinks.length; i++) {
        if (navLinks[i].addEventListener) {
          navLinks[i].addEventListener('click', this.updateMathJax)
        } else { // IE 8 and earlier
          navLinks[i].attachEvent('onclick', this.updateMathJax)
        }
      }
    },
    onMount: async function() {
      const uri = window.location.search.substring(1)
      const results = parseParams(uri)

      const keys = Object.keys(results)
      for (const i in keys) {
        if (this[keys[i]] === undefined || this[keys[i]] === -1) {
          this[keys[i]] = results[keys[i]]
        }
      }

      // Params parsed, check if you need to get groupId
      if (this.seriesId <= 0 && (!this.groupId || this.groupId <= 0)) {
        // Check if journalId provided
        if (this.journalId !== undefined) {
          // Modify the current url to have the groupId
          this.oldJourId = this.journalId

          // console.log('Here')
          this.groupId = await JournalAPI.getGroupId(this.journalId)
          if (this.groupId === -1 || this.groupId === null) {
            // Bad group ID/Journal ID
            this.loadingPage = false
            return
          }
          // When there's only a groupId, jourId should be set by the results from Details
          this.onMount()
        }
        return // No ID provided
      }
      this.loadingPage = false
      this.addTabListeners()
    },
    detailsReturn: function(items) {
      this.trackCounter5(items)
      this.fullName = items.fullName
      this.abbr = items.abbr
      this.history = items.history
      this.related = items.related
      this.recent = items.recent
      this.jourCode = items.jourCode
      this.jourId = items.jourId
      this.isRefList = items.isRefList
      this.internal = items.internal
      this.internalNotes = items.internalNotes
      this.groupDetailsTable = items.groupDetailsTable
      this.notesLoaded = true
      this.jourCodeLookup = items.jourCodeLookup
      this.unassigned = genUnassigned(items.unassigned)
      this.setTitle(this.abbr)
      if (this.related) {
        // Identify which of these are journals
        const relatedJourIds = []
        // let relatedJourIds = this.related.map(x => x.relid)
        for (const i in this.related) {
          if (this.related[i].relkey !== 'SER' && this.joursFound.indexOf(this.related[i].relid) === -1 && this.related[i].relid !== undefined) {
            relatedJourIds.push(this.related[i].relid)
            this.joursFound.push(this.related[i].relid)
          }
        }
        if (relatedJourIds.length > 0) {
          this.getGroupIds(relatedJourIds)
        }
      }
    },
    mcqReturn: function(items) {
      this.mcqValue = items.mcqValue
      this.mcqAllValue = items.mcqAllValue
    },
    getGroupIds: async function(idList) {
      const seenGroups = []
      // ... For some reason 'null' values keep getting added
      const journals = await JournalAPI.getGroupIds(idList.filter(x => x !== null))
      for (const i in journals) {
        this.jourGroupLookup[journals[i].jourid] = journals[i].groupId
        seenGroups.push(journals[i].groupId)
      }
      // Check if any of the seen groups before
      for (const i in seenGroups) {
        if (seenGroups.lastIndexOf(seenGroups[i]) > i) {
          if (this.duplicateGroups.indexOf(seenGroups[i]) === -1) {
            this.duplicateGroups.push(seenGroups[i])
          }
        }
      }
    },
    trackCounter5(details) {
      if (this.alreadyTracked) return
      if (!this.isJournal) {
        trackSerieCounter5({
          id: this.seriesId,
          title: details.fullName,
          publisher: details.publisher,
        })
      } else if (this.isJournal) {
        trackJournalCounter5({
          id: details.jourId,
          title: details.fullName,
          publisher: details.publisher,
        })
      }

      this.alreadyTracked = true
    },
  },
}

</script>
<style>
@import '../assets/views/msn_serials_profile.css';
</style>
